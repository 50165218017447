import React, { Component } from 'react';
import styled from 'styled-components';
import { SearchStateConsumer } from '../context/SearchContext';
import ResultsSection from '../components/SearchResults/ResultsSection';
import { colors } from '../style-utilities/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const StyledSearchResults = styled.div`
  padding-bottom: calc(100vh - 519px);
  padding-top: 50px;

  form {
    display: flex;
    input::placeholder {
      color: ${colors.primaryGray};
    }
    input[type='text'] {
      /* Remove styles, must use input[type=text] selector to remove iOS box-shadow styles */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Add styles */
      border-radius: 5px 0 0 5px;
      border-width: 1px 0 1px 1px;
      width: 100%;
      max-width: 450px;
      padding: 0 8px;
      height: 2.5rem;
      border-style: solid;
      border-left: 1px solid ${colors.primaryGray};
      border-bottom: 1px solid ${colors.primaryGray};
      border-top: 1px solid ${colors.primaryGray};
    }

    .iconBox {
      width: 2rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.white};
      border-radius: 0 5px 5px 0;
      border-right: 1px solid ${colors.primaryGray};
      border-bottom: 1px solid ${colors.primaryGray};
      border-top: 1px solid ${colors.primaryGray};
      border-right: 1px solid ${colors.primaryGray};
      border-left: 0;
    }
  }
`;

class SearchResults extends Component {
  state = {
    searchTerm: ''
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const term = urlParams.get('term');

    if (term) {
      this.context.updateSearchTerm(term);
      setTimeout(() => this.context.searchSubmit(), 100);
    }
  }

  render() {
    return (
      <SearchStateConsumer>
        {searchContext => {
          return searchContext.searchResults.pages.length > 0 ||
            searchContext.searchResults.posts.length > 0 ||
            searchContext.searchResults.whitePapers.length > 0 ||
            searchContext.searchResults.caseStudies.length > 0 ||
            // searchContext.searchResults.users.length > 0 ||
            searchContext.searchResults.news.length > 0 ? (
            <StyledSearchResults className="page-container has-results">
              <form
                onSubmit={event => {
                  event.preventDefault();
                  searchContext.searchSubmit();
                }}
              >
                <input
                  type="text"
                  value={searchContext.searchTerm}
                  onChange={event =>
                    searchContext.updateSearchTerm(event.target.value)
                  }
                />
                <button type="submit" className="iconBox">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>
              <ResultsSection
                type="Posts"
                searchResults={searchContext.searchResults.posts}
              />
              <ResultsSection
                type="White Papers"
                searchResults={searchContext.searchResults.whitePapers}
              />
              <ResultsSection
                type="News"
                searchResults={searchContext.searchResults.news}
              />
              <ResultsSection
                type="Case Studies"
                searchResults={searchContext.searchResults.caseStudies}
              />
              <ResultsSection
                type="Pages"
                searchResults={searchContext.searchResults.pages}
              />
              {/* <ResultsSection
                type="Authors"
                searchResults={searchContext.searchResults.users}
              /> */}
            </StyledSearchResults>
          ) : (
            <StyledSearchResults className="page-container no-results">
              <form
                onSubmit={event => {
                  event.preventDefault();
                  searchContext.searchSubmit();
                }}
              >
                <input
                  type="text"
                  value={searchContext.searchTerm}
                  onChange={event =>
                    searchContext.updateSearchTerm(event.target.value)
                  }
                />
                <button type="submit" className="iconBox">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </form>
              <h3>No results found</h3>
            </StyledSearchResults>
          );
        }}
      </SearchStateConsumer>
    );
  }
}

SearchResults.contextType = SearchStateConsumer;

export default SearchResults;
