import React from 'react';
import { capitalize } from '../../utilities/stringUtils';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { colors } from '../../style-utilities/variables';

const StyledLink = styled.div`
  a {
    font-weight: bold;
    color: ${colors.secondaryAlt};
  }
`;

const ResultsSection = ({ type, searchResults }) => {
  return (
    <div>
      {searchResults.length ? (
        <h3>
          Search Results for {capitalize(type)} ({searchResults.length})
        </h3>
      ) : null}
      {type === 'Pages' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  {(() => {
                    if (result.slug == 'blog') {
                      return (
                        <Link to={`/insights/${result.slug}`}>
                          {result.title.rendered}
                        </Link>
                      );
                    } else if (result.slug == 'verticals') {
                      return (
                        <Link to={`/results/${result.slug}`}>
                          {result.title.rendered}
                        </Link>
                      );
                    } else if (result.slug == 'white-papers') {
                      return (
                        <Link to={`/insights/${result.slug}`}>
                          {result.title.rendered}
                        </Link>
                      );
                    } else if (result.slug == 'case-studies') {
                      return (
                        <Link to={`/results/${result.slug}`}>
                          {result.title.rendered}
                        </Link>
                      );
                    } else if (result.slug == 'news') {
                      return (
                        <Link to={`/about/${result.slug}`}>
                          {result.title.rendered}
                        </Link>
                      );
                    }
                  })()}
                </StyledLink>
                {ReactHtmlParser(
                  (result.acf.excerpt && result.acf.excerpt.rendered) ||
                    result.excerpt.rendered
                )}
              </li>
            );
          })}
        </ul>
      )}
      {type === 'Posts' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  <Link to={`/insights/blog/${result.slug}`}>
                    {result.title.rendered}
                  </Link>
                </StyledLink>
                {ReactHtmlParser(
                  (result.acf.excerpt && result.acf.excerpt.rendered) ||
                    result.excerpt.rendered
                )}
              </li>
            );
          })}
        </ul>
      )}
      {type === 'Case Studies' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  <Link to={`/results/case-studies/${result.slug}`}>
                    {result.title.rendered}
                  </Link>
                </StyledLink>
                {ReactHtmlParser(
                  (result.acf && result.acf.excerpt) ||
                    result.acf.excerpt
                )}
              </li>
            );
          })}
        </ul>
      )}
      {type === 'News' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  <Link to={`/about/news/${result.slug}`}>
                    {result.title.rendered}
                  </Link>
                </StyledLink>
                {ReactHtmlParser(
                  (result.acf && result.acf.excerpt) ||
                    result.acf.excerpt
                )}
              </li>
            );
          })}
        </ul>
      )}
      {type === 'White Papers' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  <Link to={`/insights/white-papers/${result.slug}`}>
                    {result.title.rendered}
                  </Link>
                </StyledLink>
                {ReactHtmlParser(
                  (result.acf.excerpt && result.acf.excerpt.rendered) ||
                    result.excerpt.rendered
                )}
              </li>
            );
          })}
        </ul>
      )}
      {type === 'Authors' && (
        <ul>
          {searchResults.map(result => {
            return (
              <li style={{ listStyle: 'none' }} key={result.id}>
                <StyledLink>
                  {/* <Link to={`/blog/author/${result.slug}`}>{result.title.rendered}</Link> */}
                </StyledLink>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ResultsSection;
